export const ourStaffSays = [
  {
    id: 1,
    namesOfStaff: "Timo",
    photoOfStaff: require("../assets/images/certificate5.png"),
    Specialization: "Manager",
    content:
      "I am so glad I took this online aviation course. It was thorough and comprehensive, and I feel confident in my knowledge of the aviation industry.Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sunt alias vitae unde ut vel tempora mollitia laudantium provident officia quis fugit voluptatem consectetur, necessitatibus distinctio reiciendis omnis ad excepturi?",
  },
  {
    id: 2,
    namesOfStaff: "Timo",
    photoOfStaff: require("../assets/images/Sdudent3.jpeg"),
    Specialization: "Manager",
    content:
      "The instructors in this online aviation course were amazing. They were knowledgeable and patient, and always went the extra mile to ensure that we understood the material.This online aviation course was a game-changer for me. It gave me the tools I needed to succeed in my aviation career, and I can't recommend it enough",
  },
  {
    id: 3,
    namesOfStaff: "Timo",
    photoOfStaff: require("../assets/images/mainCourse.jpg"),
    Specialization: "Manager",
    content:
      "The flexibility of this online aviation course was key for me. I was able to complete the coursework on my own time, which made it possible for me to balance work and school.The course materials in this online aviation course were top-notch. The videos and interactive modules were engaging and informative, and really helped me to retain the information.",
  },
  {
    id: 4,
    namesOfStaff: "Timo",
    photoOfStaff: require("../assets/images/TomStudent2.jpeg"),
    Specialization: "Manager",
    content:
      "The support provided by the instructors in this online aviation course was exceptional. They were always available to answer questions and offer guidance,The online aviation course was well-structured and easy to follow. It was clear that the instructors had put a lot of thought and effort into designing the course",
  },
  {
    id: 5,
    namesOfStaff: "Timo",
    photoOfStaff: require("../assets/images/Student1.jpeg"),
    Specialization: "Manager",
    content:
      "I was hesitant to take an online aviation course, but I'm so glad I did. The quality of the instruction and materials was just as good as any in-person course I've taken,This online aviation course was exactly what I needed to take my career to the next level. I feel much more confident in my abilities as a result of taking it.",
  },
  {
    id: 6,
    namesOfStaff: "Timo",
    photoOfStaff: require("../assets/images/TomStudent2.jpeg"),
    Specialization: "Manager",
    content:
      "I highly recommend this online hotel management course. The instructors were knowledgeable and supportive, and the coursework was relevant and practical,The online hotel management course was an excellent investment in my career. I gained a deep understanding of the industry, and the coursework was challenging and rewarding.",
  },
  {
    id: 7,
    namesOfStaff: "Timo",
    photoOfStaff: require("../assets/images/Student1.jpeg"),
    Specialization: "Manager",
    content:
      "The flexibility of the online hotel management course was a huge plus for me. I was able to complete the coursework on my own time, which made it possible to balance work and school,I was impressed by the quality of the online hotel management course. The instructors were experienced and engaging, and the coursework was informative and practical.",
  },
  {
    id: 8,
    namesOfStaff: "Timo",
    photoOfStaff: require("../assets/images/TomPhoto.jpeg"),
    Specialization: "Manager",
    content:
      "This online hotel management course exceeded my expectations. I learned so much about the industry and the skills required to succeed in a career in hotel management.The instructors in this online hotel management course were top-notch. They were always available to answer questions and provide feedback on assignments.",
  },
];
