export const MenuItems = [
  {
    title: "Training",
    path: "/training",
    cName: "dropdown-link",
  },
  {
    title: "Air Ticheting",
    path: "/air-ticheting",
    cName: "dropdown-link",
  },
  {
    title: "Consultancy",
    path: "/consultancy",
    cName: "dropdown-link",
  },
  {
    title: "Visa Application",
    path: "/visa-application",
    cName: "dropdown-link",
  },
  {
    title: "Hotel Reservation",
    path: "/visa-application",
    cName: "dropdown-link",
  },
];
