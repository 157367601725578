import React, { useState } from "react";
import HeaderPageComponent from "../../components/headerPages/HeaderPageComponent";
import ButtonComponent from "../../components/Buttons/ButtonComponent";
import { MdGrading } from "react-icons/md";
import StartControl from "../homePages/upcomingCourses/StartControl";
import SocialMediaIcons from "../../components/socialMedia/SocialMediaIcons";
import "./service.css";
import { allUpcammingCourses } from "../../database/CoursesTest";
// import FormForReview from "./formForReview/FormForReview";
import AsideCourse from "../../components/courseAside/AsideCourse";
import Footer from "../../components/footer/Footer";
import { useParams } from "react-router-dom";
import WhoShoudAttent from "./Lessons/WhoShoudAttent";
function Service() {
  const [itemService] = useState(allUpcammingCourses);
  let params = useParams();
  let neededId = parseInt(params.serviceId);
  console.log("I got the id ::: ", params.serviceId);

  return (
    <div
      style={{ width: "100vw", height: "100vh" }}
      className="service__main-container"
    >
      <HeaderPageComponent
        title="Course"
        prevLink="/services"
        prevLinkText="Courses"
        nextLink=""
        nextLinkText="Service_detail"
      />
      <section className="allContentContainer  primaryBackGroundWhiteColor ">
        {itemService &&
          itemService
            .filter((service) => service.id === neededId)
            .map((item, id) => {
              return (
                <aside className="leftSide p-0 w-100 " key={id}>
                  <section>
                    <div className="imageServiceContainer--content">
                      <img
                        src={item.courseImage}
                        alt={item.altImage}
                        className="p-0 imageServiceContainer"
                      />
                    </div>
                    <div className="serviceContent">
                      <h1
                        className="py-4 secondaryTextBlueColor"
                        style={{ fontWeight: 800 }}
                      >
                        {" "}
                        {item.titleInShortName} For Today
                      </h1>
                      <div className="takeThisCoursContainer">
                        <div className="lecture">
                          <div className="LectureIconContainer">
                            <div className="px-2 iconLectureContainer">
                              <div style={{ fontSize: "3rem" }}>
                                <MdGrading />
                              </div>
                              {item.teacher &&
                                item.teacher.map((teacherDetail, teacherId) => {
                                  return (
                                    <div key={teacherId}>
                                      <p className="lectureText">Lecture</p>
                                      <p>{teacherDetail.position}</p>
                                    </div>
                                  );
                                })}
                            </div>
                            <div className="TextContainer">
                              <p className="lectureText">Category</p>
                              <p>{item.titleInShortName}</p>
                            </div>
                            <div className="reviews TextContainer">
                              <StartControl />
                              <p>reviews</p>
                            </div>
                          </div>
                        </div>

                        <div id="buttonLessonContent">
                          {item.lessonDetails &&
                            item.lessonDetails.map((lessonDetail) => {
                              return (
                                <div
                                  className="contextOfLesson"
                                  key={lessonDetail.id}
                                >
                                  <h1 className="secondaryTextBlueColor contextOfLessonText ">
                                    {lessonDetail.money} Frw
                                  </h1>
                                </div>
                              );
                            })}
                          <div className="btnContainer">
                            <ButtonComponent
                              text="TAKE THIS COURSE"
                              className=" btnBlue takeThisCourseButton px-5"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <section>
                      {item.CourseContent &&
                        item.CourseContent.map(
                          (courseContent, courseContentId) => {
                            return (
                              <div
                                className="courseDescription"
                                key={courseContentId}
                              >
                                <h3
                                  className="courseTitle "
                                  style={{ color: "primaryTextOrangeColor" }}
                                >
                                  Course Description
                                </h3>
                                <p>{courseContent.fistParagrap}</p>
                                <p>{courseContent.SecondParagrapth}</p>
                                <p>{courseContent.thirdParagraph}</p>
                                <p>{courseContent.FouthParagraph}</p>
                                <p>{courseContent.FiveParagraph}</p>
                                <p>{courseContent.SixthParagraph}</p>
                              </div>
                            );
                          }
                        )}

                      <article>
                        <h3 className="courseTitle">Course Info</h3>
                        <div></div>
                        <div className="aside__list-group">
                          {item.lessonDetails &&
                            item.lessonDetails.map((lessonDetail, id) => {
                              return (
                                <ul
                                  className="list-group list-group-flush aside__list-group"
                                  key={id}
                                >
                                  <li className="list-group-item">
                                    {" "}
                                    STUDENTS:{" "}
                                    <span className="dateCourseContent ">
                                      {lessonDetail.studentAvailable}
                                    </span>
                                  </li>
                                  <li className="list-group-item">
                                    START COURSE:{" "}
                                    <span className="dateCourseContent">
                                      {lessonDetail.DateOfLesson}
                                    </span>
                                  </li>
                                  <li className="list-group-item">
                                    {" "}
                                    DURATION:{" "}
                                    <span className="dateCourseContent">
                                      {lessonDetail.TimeScheduled}
                                    </span>
                                  </li>
                                  <li className="list-group-item">
                                    PREREQUISITES:{" "}
                                    <span className="dateCourseContent">
                                      {lessonDetail.Requesties}
                                    </span>
                                  </li>
                                  <li className="list-group-item">
                                    Number Of Lessons :
                                    <span>{lessonDetail.numberOfLessons}</span>
                                  </li>
                                </ul>
                              );
                            })}
                        </div>
                        <section>
                          {/* Lesson section */}
                          <div className="aside__list-group">
                            <h1 className="courseTitle pt-4 pb-2 primaryTextOrangeColor">
                              Lessons
                            </h1>
                            {item.lessonDetails &&
                              item.lessonDetails.map((lessonDetail, id) => {
                                return (
                                  <ul
                                    className="list-group list-group-flush aside__list-group"
                                    key={id}
                                  >
                                    {lessonDetail.subDetails.map(
                                      (subMenuParagraph, paragraphId) => {
                                        return (
                                          <li
                                            className="list-group-item"
                                            key={paragraphId}
                                          >
                                            <p>{subMenuParagraph.subCourse1}</p>
                                            <p>{subMenuParagraph.subCourse2}</p>
                                            <p>{subMenuParagraph.subCourse3}</p>
                                            <p>{subMenuParagraph.subCourse4}</p>
                                            <p>{subMenuParagraph.subCourse5}</p>
                                            <p>{subMenuParagraph.subCourse6}</p>
                                            <p>{subMenuParagraph.subCourse7}</p>
                                            <p>{subMenuParagraph.subCourse8}</p>
                                            <p>{subMenuParagraph.subCourse9}</p>
                                            <p>
                                              {subMenuParagraph.subCourse10}
                                            </p>
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                );
                              })}
                          </div>
                        </section>
                      </article>
                    </section>
                    <section>
                      {item.teacher &&
                        item.teacher.map((teacherDetails, teacherId) => {
                          return (
                            <div
                              className="lectureInfo text-center text-md-start"
                              key={teacherId}
                            >
                              <div className="imageLectureContainer">
                                <img
                                  src={teacherDetails.TeacheImage}
                                  alt={teacherDetails.AltTeacherImage}
                                  style={{ width: "100%" }}
                                  className="img-fluid LectureImage"
                                />
                              </div>
                              <div className="lectureDescription ">
                                <h4>Teacher : {teacherDetails.TeacherName}</h4>
                                <p className="px-4">
                                  {teacherDetails.TecherContent}
                                </p>
                                {teacherDetails.socialMedia &&
                                  teacherDetails.socialMedia.map(
                                    (socialMedialDetails, socialMediaId) => {
                                      return (
                                        <div
                                          className="socialMediaContainer"
                                          key={socialMediaId}
                                        >
                                          <SocialMediaIcons
                                            facebook={`www.${socialMedialDetails.facebook}.com`}
                                            twitter={`www.${socialMedialDetails.twitter}.com`}
                                            instagram={`www.${socialMedialDetails.instagram}.com`}
                                            youtube={`www.${socialMedialDetails.youtube}.com`}
                                          />
                                        </div>
                                      );
                                    }
                                  )}
                              </div>
                            </div>
                          );
                        })}
                    </section>
                    <hr />
                    {/* <Rating /> */}
                    <hr />
                    {/* <section>
                      <FormForReview titleOfLesson={item.titleInShortName} />
                    </section> */}
                  </section>
                  <section>
                    {/* Lesson whoShoulAttend */}
                    <div className="aside__list-group">
                      <h1 className="courseTitle pt-4 pb-2 primaryTextOrangeColor">
                        WHO SHOULD ATTEND ?
                      </h1>
                      {item.whoShoulAttend &&
                        item.whoShoulAttend.map((whoShoulAttendDetail) => {
                          return (
                            <ul className="list-group list-group-flush aside__list-group">
                              <li className="list-group-item">
                                <p>{whoShoulAttendDetail.Reason1}</p>
                                <p>{whoShoulAttendDetail.Reason2}</p>
                                <p>{whoShoulAttendDetail.Reason3}</p>
                                <p>{whoShoulAttendDetail.Reason4}</p>
                              </li>
                            </ul>
                          );
                        })}
                      <div>
                        <h1 className="courseTitle pt-4 pb-2 primaryTextOrangeColor">
                          How to obtain your diploma?
                        </h1>
                        <ul className="list-group list-group-flush aside__list-group">
                          <li className="list-group-item">
                            {item.examination}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </section>
                </aside>
              );
            })}

        <aside>
          <AsideCourse />
        </aside>
      </section>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default Service;
