export const AdvisorData = [
  {
    id: 1,
    name: "NTAGANZWA Patience",
    image: require("../assets/images/blanckImage.jpg"),
    position: "Sales And Ticketing Officer",
    facebook: "www.facebook.com",
    twitter: "www.twitter.com",
    instagram: "www.instagram.com",
    whatsup: "0782439775",
  },
  {
    id: 2,
    name: "MUGABO Cassien",
    image: require("../assets/images/blanckImage.jpg"),
    position: "Consultant",
    facebook: "www.facebook.com",
    twitter: "www.twitter.com",
    instagram: "www.instagram.com",
    whatsup: "0782439775",
  },
  {
    id: 3,
    name: "HAGUMA Timothee",
    image: require("../assets/images/blanckImage.jpg"),
    position: "Instructor",
    facebook: "www.facebook.com",
    twitter: "www.twitter.com",
    instagram: "www.instagram.com",
    whatsup: "0782439775",
  },
  {
    id: 4,
    name: "UWAMWEZI Alice",
    image: require("../assets/images/blanckImage.jpg"),
    position: "Instructor",
    facebook: "www.facebook.com",
    twitter: "www.twitter.com",
    instagram: "www.instagram.com",
    whatsup: "0784068093",
  },
];
