import React from "react";

function ModalComponent() {
  return (
    <div>
      <i className="baseline-help_outline"></i>
    </div>
  );
}

export default ModalComponent;
