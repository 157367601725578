export const timtomTeam = [
  {
    id: 1,
    name: "UMUHOZA Jacqueline",
    image: require("../assets/images/blanckImage.jpg"),
    positon: "Managing Director",
    facebook: "www.facebook.com",
    twitter: "www.twitter.com",
    youtub: "www.youtub.com",
    instagram: "www.instagram.com",
    whatsup: "0782439775",
  },
];
