export const allUpcammingCourses = [
  {
    id: 1,
    courseImage: "/timimages/images/Student.jpeg",
    altImage: "AIRLINE AND AIRPORT MANAGEMENT  ",
    Context:
      "The airline industry is complex and involves many different aspects, including fleet management, scheduling, pricing, revenue management, and customer service. It is important to understand the key components of the airline industry and how they work together.",
    titleInShortName: "AIRLINE AND AIRPORT MANAGEMENT  ",
    category: "airport",
    CourseContent: [
      {
        id: 1,
        fistParagrap:
          "The airline industry: The airline industry is complex and involves many different aspects, including fleet management, scheduling, pricing, revenue management, and customer service. It is important to understand the key components of the airline industry and how they work together.",
        SecondParagrapth:
          "The airport industry: Airports are critical components of the airline industry, and it is important to understand the different aspects of airport management, including airport planning, operations, security, and revenue management.",
        thirdParagraph:
          "Safety and security: Safety and security are top priorities in the airline and airport MANAGEMENT industry. It is important to understand the various regulations and procedures related to safety and security and how to implement them effectively.",
      },
    ],
    teacher: [
      {
        id: 1,
        TeacheImage: "/timimages/images/Student.jpeg",
        AltTeacherImage: "Teacher Keza",
        TeacherName: "Michel Niwemwiza",
        TecherContent:
          "Good morning/afternoon, class! Today we're going to be talking about airlines and airports. We'll learn about the different types of airlines, how they operate, and the various services they proid to passengers. We'll also discuss the different parts of an airport and how they function together to make air travel possible. By the end of this lesson, you'll have a better understanding of the air transportation industry and how it impacts our daily lives. So, let's get started!",
        position: "Admin",
        professionalism: "AirPort",
        socialMedia: [
          {
            id: 1,
            Email: "www.Email.com",
            facebook: "www.facebook.com",
            twitter: "www.twitter.com",
            instagram: "www.instagram.com",
          },
        ],
      },
    ],
    lessonDetails: [
      {
        id: 1,
        TitleInFullName:
          "ADVANCED CERTIFICATE  IN AIRLINE AND AIRPORT MANAGEMENT MANAGEMENT",
        money: "500,000",
        likes: 4,
        studentAvailable: 50,
        courseTime: "1_month",
        numberOfLessons: 4,
        DateOfLesson: "04/02/2022",
        TimeScheduled: "42h 30m",
        Requesties: "Yes",
        subDetails: [
          {
            id: 1,
            subCourse1: "LESSON 1: Airport operations",
            subCourse2: "LESSON 2: Airport Ground Handling",
            subCourse3: "LESSON 3: Airline and airport MANAGEMENT Marketing",
            subCourse4: "LESSON 4: Ground operations management ",
          },
        ],
      },
    ],
    whoShoulAttend: [
      {
        Reason1: "Travel agents",
        Reason2: "Airline reservation agents",
        Reason3: "Tour operators",
        Reason4: "Call center agents",
      },
    ],
    examination:
      "A passing mark of 60% is required on all assignments and exams. A special  distinction is awarded to candidates who obtain a distinction in all, or all but one, all course exams and assignments that make up this Diploma..",
  },
  {
    id: 2,
    courseImage: "/timimages/images/book.jpg",
    altImage: "AIR CARGO MANAGEMENT  ",
    Context:
      "content on Air cargo handling and We teach all about Dangerous Goods with Logistics Airlines and airports work together closely, and it is important to understand the partnerships ",
    titleInShortName: "AIR CARGO MANAGEMENT   ",
    category: "airport",
    CourseContent: [
      {
        id: 1,
        fistParagrap:
          "The airline industry: The airline industry is complex and involves many different aspects, including fleet management, scheduling, pricing, revenue management, and customer service. It is important to understand the key components of the airline industry and how they work together.",
        SecondParagrapth:
          "The airline industry: The airline industry is complex and involves many different aspects, including fleet management, scheduling, pricing, revenue management, and customer service. It is important to understand the key components of the airline industry and how they work together.",
        thirdParagraph:
          "The airline industry: The airline industry is complex and involves many different aspects, including fleet management, scheduling, pricing, revenue management, and customer service. It is important to understand the key components of the airline industry and how they work together.",
      },
    ],
    teacher: [
      {
        id: 1,
        TeacheImage: "/timimages/images/Student.jpeg",
        AltTeacherImage: "Teacher Keza",
        TeacherName: "Cyiza Makuza",
        TecherContent:
          "Hello, everyone! Today's lesson is all about air cargo management. We're going to learn about the logistics of moving goods by air, including the different types of cargo, how it's packaged and handled, and the various regulations and security measures in place to ensure its safe transport. We'll also discuss the different players in the air cargo industry, from airlines to freight forwarders, and the roles they play in the movement of goods around the world. By the end of this lesson, you'll have a better understanding of the challenges and opportunities in air cargo management, as well as the skills needed to manage an air cargo operation. So, let's get started!",
        position: "Director Manager",
        professionalism: "Air Cargo",
        socialMedia: [
          {
            id: 1,
            Email: "www.Email.com",
            facebook: "www.facebook.com",
            twitter: "www.twitter.com",
            instagram: "www.instagram.com",
          },
        ],
      },
    ],
    lessonDetails: [
      {
        id: 1,
        TitleInFullName: "ADVANCED CERTIFICATE IN AIR CARGO MANAGEMENT ",
        money: "500,000",
        likes: 3,
        studentAvailable: 23,
        courseTime: "1_month",
        numberOfLessons: 3,
        DateOfLesson: "04/02/2022",
        TimeScheduled: "56h 30m",
        Requesties: "Yes",
        subDetails: [
          {
            id: 2,
            subCourse1: "LESSON 1: Air cargo handling  ",
            subCourse2: "LESSON 2: Dangerous Goods",
            subCourse3: "LESSON 3: Logistics ",
          },
        ],
      },
    ],
    whoShoulAttend: [
      {
        Reason1: "All Newcomers Students",
      },
    ],
    examination:
      "A passing mark of 60% is required on all assignments and exams. A special  distinction is awarded to candidates who obtain a distinction in all, or all but one, all course exams and assignments that make up this Diploma..",
  },
  {
    id: 3,
    courseImage: "/timimages/slideImages/A.jpg",
    altImage: "FAUNDATION IN TRAVEL AND TOURISM  ",
    Context:
      "We'll learn about the different types of tourism from leisure to business travel,",
    titleInShortName: "FAUNDATION IN TRAVEL AND TOURISM ",
    category: "tourism",
    CourseContent: [
      {
        id: 1,
        fistParagrap:
          "The airline industry: The airline industry is complex and involves many different aspects, including fleet management, scheduling, pricing, revenue management, and customer service. It is important to understand the key components of the airline industry and how they work together.",
        SecondParagrapth:
          "The airport industry: Airports are critical components of the airline industry, and it is important to understand the different aspects of airport management, including airport planning, operations, security, and revenue management.",
        thirdParagraph:
          "Safety and security: Safety and security are top priorities in the airline and airport MANAGEMENT industry. It is important to understand the various regulations and procedures related to safety and security and how to implement them effectively.",
      },
    ],
    teacher: [
      {
        id: 1,
        TeacheImage: "/timimages/images/Student.jpeg",
        AltTeacherImage: "Teacher Keza",
        TeacherName: "Michel",
        TecherContent:
          "We'll learn about the different types of tourism, from leisure to business travel, and the factors that influence consumer behavior in this industry. We'll also discuss the various functions of tourism management, including marketing, operations, and sustainability, and how they work together to create a successful travel experience. Additionally, we'll look at the different types of tourism destinations and the various tourism products that they offer. By the end of this lesson, you'll have a soidunderstanding of the travel and tourism industry, including the challenges and opportunities that come with managing this dynamic field",
        position: "Admin",
        professionalism: "Travel and Tourism",
        socialMedia: [
          {
            id: 1,
            Email: "www.Email.com",
            facebook: "www.facebook.com",
            twitter: "www.twitter.com",
            instagram: "www.instagram.com",
          },
        ],
      },
    ],
    lessonDetails: [
      {
        id: 1,
        TitleInFullName:
          "ADVANCED CERTIFICATE  IN AIRLINE AND AIRPORT MANAGEMENT MANAGEMENT",
        money: "500,000",
        likes: 4,
        studentAvailable: 47,
        courseTime: "1_month",
        numberOfLessons: 4,
        DateOfLesson: "04/02/2022",
        TimeScheduled: "42h 30m",
        Requesties: "Yes",
        subDetails: [
          {
            id: 1,
            subCourse1: " LESSON 1: Safety Management System",
            subCourse2: " LESSON 2: Aviation security ",
            subCourse3:
              " LESSON 3: Aviation legal environment  and crew resource management ",
            subCourse4: " LESSON 4: Ground operations management  ",
          },
        ],
      },
    ],
    whoShoulAttend: [
      {
        Reason1: "All Newcomers Students",
      },
    ],
    examination:
      "A passing mark of 60% is required on all assignments and exams. A special  distinction is awarded to candidates who obtain a distinction in all, or all but one, all course exams and assignments that make up this Diploma..",
  },
  {
    id: 4,
    courseImage: "/timimages/images/course8.jpg",
    altImage: "AVIATION MANAGEMENT",
    Context:
      "content marketing to your skill set is such a smart career move. In this course, expert Colleen Jones introduces you to the fundamentals. Discover what content marketing is—and what it isn’t. Then learn how develop a smart content marketing strategy. Find out how to correctlyidntify an.",
    titleInShortName: "AVIATION MANAGEMENT",
    category: "aviation",
    CourseContent: [
      {
        id: 1,
        fistParagrap:
          "The airline industry: The airline industry is complex and involves many different aspects, including fleet management, scheduling, pricing, revenue management, and customer service. It is important to understand the key components of the airline industry and how they work together.",
        SecondParagrapth:
          "The airport industry: Airports are critical components of the airline industry, and it is important to understand the different aspects of airport management, including airport planning, operations, security, and revenue management.",
        thirdParagraph:
          "Safety and security: Safety and security are top priorities in the airline and airport MANAGEMENT industry. It is important to understand the various regulations and procedures related to safety and security and how to implement them effectively.",
      },
    ],
    teacher: [
      {
        id: 1,
        TeacheImage: "/timimages/images/Student.jpeg",
        AltTeacherImage: "Teacher Keza",
        TeacherName: "Keza Kalisa",
        TecherContent:
          "Good morning/afternoon, class! Today we're going to be talking about airlines and airports. We'll learn about the different types of airlines, how they operate, and the various services they proid to passengers. We'll also discuss the different parts of an airport and how they function together to make air travel possible. By the end of this lesson, you'll have a better understanding of the air transportation industry and how it impacts our daily lives. So, let's get started!",
        position: "Admin",
        professionalism: "Aviation",
        socialMedia: [
          {
            id: 1,
            Email: "www.Email.com",
            facebook: "www.facebook.com",
            twitter: "www.twitter.com",
            instagram: "www.instagram.com",
          },
        ],
      },
    ],
    lessonDetails: [
      {
        id: 1,
        TitleInFullName: "ADVANCED CERTICATE IN AVIATION MANAGEMENT ",
        money: "700,000",
        likes: 4,
        studentAvailable: 50,
        courseTime: "1_month",
        numberOfLessons: 8,
        DateOfLesson: "06/03/2023",
        TimeScheduled: "122h 00m",
        Requesties: "No",
        subDetails: [
          {
            id: 4,
            subCourse1: "LESSON 1: Airport Operator Certification",
            subCourse2: "LESSON 2: Auditing technical in CAA",
            subCourse3: "LESSON 3: Aircraft operations",
            subCourse4: "LESSON 4: USOAP CMA Audit ",
            subCourse5: "LESSON 5: Aviation legal environment  ",
            subCourse6: "LESSON 6: Personnel licensing ",
            subCourse7: "LESSON 7: Commercial and aviation law ",
            subCourse8: "LESSON 8: Aircraft maintenance  management  ",
          },
        ],
      },
    ],
    whoShoulAttend: [
      {
        Reason1: "All Newcomers Students",
      },
    ],
    examination:
      "A passing mark of 60% is required on all assignments and exams. A special  distinction is awarded to candidates who obtain a distinction in all, or all but one, all course exams and assignments that make up this Diploma..",
  },
  {
    id: 5,
    courseImage: "/timimages/slideImages/K.jpg",
    altImage: "AIR CABIN CREW ",
    Context:
      "Air cabin crew, also known as flight attendants, are responsible for the safety and comfort of passengers on board commercial flights. They are trained to handle emergency situations, proid first id and ensure that passengers comply with safety regulations.",
    titleInShortName: "AIR CABIN CREW",
    category: "airport",
    CourseContent: [
      {
        id: 1,
        fistParagrap:
          "The airline industry: The airline industry is complex and involves many different aspects, including fleet management, scheduling, pricing, revenue management, and customer service. It is important to understand the key components of the airline industry and how they work together.",
        SecondParagrapth:
          "The airport industry: Airports are critical components of the airline industry, and it is important to understand the different aspects of airport management, including airport planning, operations, security, and revenue management.",
        thirdParagraph:
          "Safety and security: Safety and security are top priorities in the airline and airport MANAGEMENT industry. It is important to understand the various regulations and procedures related to safety and security and how to implement them effectively.",
      },
    ],
    teacher: [
      {
        id: 1,
        TeacheImage: "/timimages/slideImages/A.jpg",
        AltTeacherImage: "Teacher Keza",
        TeacherName: "Jisele Mukamana",
        TecherContent:
          "Good morning/afternoon, class! Today we're going to be talking about airlines and airports. We'll learn about the different types of airlines, how they operate, and the various services they proid to passengers. We'll also discuss the different parts of an airport and how they function together to make air travel possible. By the end of this lesson, you'll have a better understanding of the air transportation industry and how it impacts our daily lives. So, let's get started!",
        position: "Worker",
        professionalism: "Air Managment",
        socialMedia: [
          {
            id: 1,
            Email: "www.Email.com",
            facebook: "www.facebook.com",
            twitter: "www.twitter.com",
            instagram: "www.instagram.com",
          },
        ],
      },
    ],
    lessonDetails: [
      {
        id: 1,
        TitleInFullName: "ADVANCED CERTIFICATE IN AIR CABIN CREW ",
        money: "600,000",
        likes: 4,
        studentAvailable: 40,
        courseTime: "1_month",
        numberOfLessons: 4,
        DateOfLesson: "04/03/2023",
        TimeScheduled: "32h 30m",
        Requesties: "Yes",
        subDetails: [
          {
            id: 3,
            subCourse1: "LESSON 1: Safety management system",
            subCourse2: "LESSON 2: Air cabin crew ",
            subCourse3: "LESSON 3: Passenger handling ",
            subCourse4: "LESSON 4: Aviation security",
          },
        ],
      },
    ],
    whoShoulAttend: [
      {
        Reason1: "All Newcomers Students",
      },
    ],
    examination:
      "A passing mark of 60% is required on all assignments and exams. A special  distinction is awarded to candidates who obtain a distinction in all, or all but one, all course exams and assignments that make up this Diploma..",
  },
  {
    id: 6,
    courseImage: "/timimages/slideImages/Z.jpg",
    altImage: "Civil aviation management",
    Context:
      "In this course, expert Colleen Jones introduces you to the fundamentals. Discover what content marketing is—and what it isn’t.",
    titleInShortName: "CIVIL AVIATION MANAGEMENT ",
    category: "aviation",
    CourseContent: [
      {
        id: 1,
        fistParagrap:
          "The airline industry: The airline industry is complex and involves many different aspects, including fleet management, scheduling, pricing, revenue management, and customer service. It is important to understand the key components of the airline industry and how they work together.",
        SecondParagrapth:
          "The airport industry: Airports are critical components of the airline industry, and it is important to understand the different aspects of airport management, including airport planning, operations, security, and revenue management.",
        thirdParagraph:
          "Safety and security: Safety and security are top priorities in the airline and airport MANAGEMENT industry. It is important to understand the various regulations and procedures related to safety and security and how to implement them effectively.",
      },
    ],
    teacher: [
      {
        id: 1,
        TeacheImage: "/timimages/images/Student.jpeg",
        AltTeacherImage: "Teacher Keza",
        TeacherName: "Michel",
        TecherContent:
          "Good morning/afternoon, class! Today we're going to be talking about airlines and airports. We'll learn about the different types of airlines, how they operate, and the various services they proid to passengers. We'll also discuss the different parts of an airport and how they function together to make air travel possible. By the end of this lesson, you'll have a better understanding of the air transportation industry and how it impacts our daily lives. So, let's get started!",
        position: "Admin",
        professionalism: "AirPort",
        socialMedia: [
          {
            id: 1,
            Email: "www.Email.com",
            facebook: "www.facebook.com",
            twitter: "www.twitter.com",
            instagram: "www.instagram.com",
          },
        ],
      },
    ],
    lessonDetails: [
      {
        id: 1,
        TitleInFullName: "ADVANCED CERTICATE IN AVIATION MANAGEMENT ",
        money: "300,000",
        likes: 4,
        studentAvailable: 50,
        courseTime: "2_month",
        numberOfLessons: 6,
        DateOfLesson: "04/02/2022",
        TimeScheduled: "42h 30m",
        Requesties: "Yes",
        subDetails: [
          {
            id: 1,
            subCourse1: " LESSON 1: Personnel licensing",
            subCourse2: " LESSON 2: Airport certification",
            subCourse3: " LESSON 3: Auditing techniques in civil aviation",
            subCourse4: " LESSON 4: Safety management system ",
            subCourse5: " LESSON 5: USOAP CMA AUDIT",
            subCourse6: " LESSON 6: Aviation management",
          },
        ],
      },
    ],
    whoShoulAttend: [
      {
        Reason1: "All Newcomers Students",
      },
    ],
    examination:
      "A passing mark of 60% is required on all assignments and exams. A special  distinction is awarded to candidates who obtain a distinction in all, or all but one, all course exams and assignments that make up this Diploma..",
  },
  {
    id: 7,
    courseImage: "/timimages/images/icaoo.jpeg",
    altImage: "ICAO COURSES ",
    Context:
      "In this course,Provides CAA inspectorate staff and industry experts with information regarding the   USOAP concepts and methodologies",

    titleInShortName: "ICAO COURSES MANAGEMENT ",
    category: "icao",
    CourseContent: [
      {
        id: 1,
        fistParagrap:
          "Provides CAA inspectorate staff and industry experts with information regarding the  USOAP concepts and methodologies",

        SecondParagrapth:
          "Allows aviation safety experts within the CAA and the industry to gain further knowledge about the ICAO audit areas relevant to their expertise and",

        thirdParagraph:
          "Helps States and industry experts to gain an understanding of the performance of safety oversight in their respective audit area(s).",
      },
    ],
    teacher: [
      {
        id: 1,
        TeacheImage: "/timimages/images/Student.jpeg",
        AltTeacherImage: "Teacher Keza",
        TeacherName: "Michel",
        TecherContent:
          "Good morning/afternoon, class! Today we're going to be talking about airlines and airports. We'll learn about the different types of airlines, how they operate, and the various services they proid to passengers. We'll also discuss the different parts of an airport and how they function together to make air travel possible. By the end of this lesson, you'll have a better understanding of the air transportation industry and how it impacts our daily lives. So, let's get started!",
        position: "Admin",
        professionalism: "AirPort",
        socialMedia: [
          {
            id: 1,
            Email: "www.Email.com",
            facebook: "www.facebook.com",
            twitter: "www.twitter.com",
            instagram: "www.instagram.com",
          },
        ],
      },
    ],
    lessonDetails: [
      {
        id: 1,
        TitleInFullName: "ADVANCED CERTICATE IN AVIATION MANAGEMENT ",
        money: "300,000",
        likes: 4,
        studentAvailable: 20,
        courseTime: "90 days",
        numberOfLessons: 3,
        DateOfLesson: "04/02/2022",
        TimeScheduled: "40h - between - 60h",
        Requesties: "Yes",
        subDetails: [
          {
            id: 1,
            subCourse1:
              " LESSON 1: Effective communication, audit principles, audit techniques, team building, time management, public speaking.",
            subCourse2:
              " LESSON 2: Overview of the ICAO USOAP, the critical elements of an effective State safety oversight system, and an overview of the USOAP audit tools.",
            subCourse3:
              " LESSON 3: Evolution of USOAP to the CMA followed by ICVM establishment and implementation, process overview, pre-mission phase, on-site phase and post-mission phase.",
            subCourse4: " LESSON 4: Final Exam of Phase 1 ",
          },
        ],
      },
    ],
    whoShoulAttend: [
      {
        Reason1: "Newcomers in Airport and Ground Operations",
      },
    ],
    examination:
      "A passing mark of 60% is required on all assignments and exams. A special  distinction is awarded to candidates who obtain a distinction in all, or all but one, all course exams and assignments that make up this Diploma..",
  },
  {
    id: 8,
    courseImage: "/timimages/images/iata.jpg",
    altImage: "ICAO COURSES ",
    Context:
      "helping you get a head start in your career at an airline, freight forwarder or ground handling This diploma will give you a well-rounded introduction to today’s complex air cargo industry,  company.",
    titleInShortName: "IATA Air Cargo Diploma ",
    category: "aviation",
    CourseContent: [
      {
        id: 1,
        fistParagrap:
          "Apply the cargo rating principles, Apply the processes involved in shipping dangerous goods.",

        SecondParagrapth:
          "Identify the needs of the shipper and the consignee, Develop an efficient and reliable transportation service that can be sold at an attractive price.",

        thirdParagraph:
          "Market cargo services to business partners and clients",
      },
    ],
    teacher: [
      {
        id: 1,
        TeacheImage: "/timimages/images/Student.jpeg",
        AltTeacherImage: "Teacher Keza",
        TeacherName: "Michel",
        TecherContent:
          "Good morning/afternoon, class! Today we're going to be talking about airlines and airports. We'll learn about the different types of airlines, how they operate, and the various services they proid to passengers. We'll also discuss the different parts of an airport and how they function together to make air travel possible. By the end of this lesson, you'll have a better understanding of the air transportation industry and how it impacts our daily lives. So, let's get started!",
        position: "Teacher",
        professionalism: "AirPort",
        socialMedia: [
          {
            id: 1,
            Email: "www.Email.com",
            facebook: "www.facebook.com",
            twitter: "www.twitter.com",
            instagram: "www.instagram.com",
          },
        ],
      },
    ],
    lessonDetails: [
      {
        id: 1,
        TitleInFullName: "ADVANCED CERTICATE IN AVIATION MANAGEMENT ",
        money: "300,000",
        likes: 4,
        studentAvailable: 20,
        courseTime: "90 days",
        numberOfLessons: 5,
        DateOfLesson: "04/02/2022",
        TimeScheduled: "40h - between - 60h",
        Requesties: "Yes",
        subDetails: [
          {
            id: 1,
            subCourse1:
              " LESSON 1: Cargo agency, airline operator, and freight forwarder operations",
            subCourse2:
              " LESSON 2: Cargo rating principles and currency regulations: conversion rates, rounding off procedures and construction rates",
            subCourse3:
              " LESSON 3: Combination of mixed consignments, rates and charges, declaration of the goods and Air Waybill completion",
            subCourse4:
              " LESSON 4: Dangerous goods classification, packing, marking, labelling, and documentation requirements",
            subCourse6:
              " LESSON 5: IATA Dangerous Goods Regulations for cargo agents and freight forwarders",
          },
        ],
      },
    ],
    whoShoulAttend: [
      {
        Reason1: "Newcomers to the air cargo and freight forwarding industry",
        Reason2:
          "Cargo agent and air freight forwarder sales and marketing staff",
        Reason3: "Airline and manufacturer reservations and acceptance staff",
        Reason4: "Cargo agent, freight forwarder and airline junior management",
      },
    ],
    examination:
      "A passing mark of 60% is required on all assignments and exams. A special distinction is awarded to candidates who obtain a distinction in all, or all but one, of all course exams and assignments that make up this Diploma.",
  },
  {
    id: 9,
    courseImage: "/timimages/images/aviation5.jpeg",
    altImage: "IATA Airport Operations Foundation Diploma ",
    Context:
      "This Diploma is designed to address the audience of prospective Airport and Ground  Operations professionals. The variety of available courses gives the opportunity to identify the key areas required to be able to perform tasks safely and efficiently.",
    titleInShortName: "IATA Air Cargo Diploma ",
    category: "aviation",
    CourseContent: [
      {
        id: 1,
        fistParagrap: "Understand the role airports play in the economy",

        SecondParagrapth: "Discover the various airport customers and partners",

        thirdParagraph:
          "Gain knowledge about the multiple operational and business functions of airports",
        FouthParagraph:
          "Acquire the tools to apply your knowledge and skills to your airport environment",
      },
    ],
    teacher: [
      {
        id: 1,
        TeacheImage: "/timimages/images/Student.jpeg",
        AltTeacherImage: "Teacher Keza",
        TeacherName: "Michel",
        TecherContent:
          "Good morning/afternoon, class! Today we're going to be talking about airlines and airports. We'll learn about the different types of airlines, how they operate, and the various services they proid to passengers. We'll also discuss the different parts of an airport and how they function together to make air travel possible. By the end of this lesson, you'll have a better understanding of the air transportation industry and how it impacts our daily lives. So, let's get started!",
        position: "Teacher",
        professionalism: "AirPort",
        socialMedia: [
          {
            id: 1,
            Email: "www.Email.com",
            facebook: "www.facebook.com",
            twitter: "www.twitter.com",
            instagram: "www.instagram.com",
          },
        ],
      },
    ],
    lessonDetails: [
      {
        id: 1,
        TitleInFullName: "ADVANCED CERTICATE IN AVIATION MANAGEMENT ",
        money: "300,000",
        likes: 4,
        studentAvailable: 20,
        courseTime: "90 days",
        numberOfLessons: 5,
        DateOfLesson: "04/02/2022",
        TimeScheduled: "40h - between - 60h",
        Requesties: "Yes",
        subDetails: [
          {
            id: 1,
            subCourse1:
              " LESSON 1: Newcomers to the air cargo and freight forwarding industry",
            subCourse2:
              " LESSON 2: Cargo agent and air freight forwarder sales and marketing staff",
            subCourse3:
              " Airline and manufacturer reservations and acceptance staff",
            subCourse4:
              " LESSON 4: Cargo agent, freight forwarder and airline junior management",
          },
        ],
      },
    ],
    whoShoulAttend: [
      {
        Reason1: "Newcomers to the air cargo and freight forwarding industry",
        Reason2:
          "Cargo agent and air freight forwarder sales and marketing staff",
        Reason3: "Airline and manufacturer reservations and acceptance staff",
        Reason4: "Cargo agent, freight forwarder and airline junior management",
      },
    ],
    examination:
      "A passing mark of 60% is required on all assignments and exams. A special  distinction is awarded to candidates who obtain a distinction in all, or all but one, all course exams and assignments that make up this Diploma..",
  },
  {
    id: 10,
    courseImage: "/timimages/images/aviation2.jpg",
    altImage: "IATA Aviation Fundamentals Diploma",
    Context:
      "This diploma will provide new graduates looking to join the air transport industry or professionals looking to move into the industry, with a solid understanding of the industry.Learn about the history of aviation, its different components and interdependencies among  airlines, airports, civil aviation authorities and air navigation services that needs to work in  harmony in order to ensure safety and efficiency. You will also discover proficient and effective ways of increasing sales and revenue with the right marketing strategy.",
    titleInShortName: "IATA Air Cargo Diploma ",
    category: "aviation",
    CourseContent: [
      {
        id: 1,
        fistParagrap: "Outline the history of aviation",

        SecondParagrapth:
          "Explain how different aviation entities are dependent on each other in order to provide a safe and efficient Aviation environment",

        thirdParagraph:
          "Describe strategic methods in order to increase and manage revenue",
        FouthParagraph:
          "Analyse how effective marketing strategies are developed within the aviation industry",
        FiveParagraph: "Describe the importance of customer service",
      },
    ],
    teacher: [
      {
        id: 1,
        TeacheImage: "/timimages/images/Student.jpeg",
        AltTeacherImage: "Teacher Keza",
        TeacherName: "Michel",
        TecherContent:
          "Good morning/afternoon, class! Today we're going to be talking about airlines and airports. We'll learn about the different types of airlines, how they operate, and the various services they proid to passengers. We'll also discuss the different parts of an airport and how they function together to make air travel possible. By the end of this lesson, you'll have a better understanding of the air transportation industry and how it impacts our daily lives. So, let's get started!",
        position: "Teacher",
        professionalism: "AirPort",
        socialMedia: [
          {
            id: 1,
            Email: "www.Email.com",
            facebook: "www.facebook.com",
            twitter: "www.twitter.com",
            instagram: "www.instagram.com",
          },
        ],
      },
    ],
    lessonDetails: [
      {
        id: 1,
        TitleInFullName: "ADVANCED CERTICATE IN AVIATION MANAGEMENT ",
        money: "300,000",
        likes: 4,
        studentAvailable: 20,
        courseTime: "90 days",
        numberOfLessons: 5,
        DateOfLesson: "04/02/2022",
        TimeScheduled: "40h - between - 60h",
        Requesties: "Yes",
        subDetails: [
          {
            id: 1,
            subCourse1: " LESSON 1: Aviation history",
            subCourse2:
              " LESSON 2: Aviation authorities and government entities",
            subCourse3: " LESSON 3: Marketing principles",
            subCourse4: " LESSON 4: Revenue Management",
            subCourse6: " LESSON 5: Airline Customer Service",
          },
        ],
      },
    ],
    whoShoulAttend: [
      {
        Reason1: "New Graduates",
        Reason2: "Young professional or professionals looking to switch career",
      },
    ],
    examination:
      "A passing mark of 60% is required on all assignments and exams. A special  distinction is awarded to candidates who obtain a distinction in all, or all but one, all course exams and assignments that make up this Diploma..",
  },
  {
    id: 11,
    courseImage: "/timimages/images/aviation3.jpeg",
    altImage: "IATA Foundation in Travel and Tourism Diploma",
    Context:
      "Acquire essential knowledge and skills to work in the travel and tourism industry and become an effective travel professional. Provide advice on flight, accommodation and tour products, and how to ensure travelers fulfill necessary travel requirements. Improve your where to travel based on their needs. Learn to use a global distribution system to create and manage reservations.",
    titleInShortName: "IATA Foundation in Travel and Tourism Diploma ",
    category: "aviation",
    CourseContent: [
      {
        id: 1,
        fistParagrap:
          "Advise customers on passport and visa requirements, as well as on travel insurance options options",

        SecondParagrapth:
          "Promote services and facilities available to airline passengers during their travel",

        thirdParagraph:
          "Interpret standard industry codes to plan and reserve travel itineraries",
        FouthParagraph:
          "Sell rail travel, car rental, hotel accommodation, cruises and pre-packaged tour products",
        FiveParagraph:
          "Provide good customer service and retain customer loyalty",
        SixthParagraph:
          "Use the Global Distribution System to make and maintain reservations and issue travel documents",
      },
    ],
    teacher: [
      {
        id: 1,
        TeacheImage: "/timimages/images/Student.jpeg",
        AltTeacherImage: "Teacher Keza",
        TeacherName: "Michel",
        TecherContent:
          "Good morning/afternoon, class! Today we're going to be talking about airlines and airports. We'll learn about the different types of airlines, how they operate, and the various services they proid to passengers. We'll also discuss the different parts of an airport and how they function together to make air travel possible. By the end of this lesson, you'll have a better understanding of the air transportation industry and how it impacts our daily lives. So, let's get started!",
        position: "Teacher",
        professionalism: "AirPort",
        socialMedia: [
          {
            id: 1,
            Email: "www.Email.com",
            facebook: "www.facebook.com",
            twitter: "www.twitter.com",
            instagram: "www.instagram.com",
          },
        ],
      },
    ],
    lessonDetails: [
      {
        id: 1,
        TitleInFullName: "ADVANCED CERTICATE IN AVIATION MANAGEMENT ",
        money: "300,000",
        likes: 4,
        studentAvailable: 20,
        courseTime: "90 days",
        numberOfLessons: 5,
        DateOfLesson: "04/02/2022",
        TimeScheduled: "40h - between - 60h",
        Requesties: "Yes",
        subDetails: [
          {
            id: 1,
            subCourse1:
              " LESSON 1: Advise customers on passport and visa requirements",
            subCourse2:
              " LESSON 2: Aviation authorities and government entities",
            subCourse3: " LESSON 3: Airline passengers",
            subCourse4: " LESSON 4: Revenue Management",
            subCourse6: " LESSON 5: Airline Customer Service",
          },
        ],
      },
    ],
    whoShoulAttend: [
      {
        Reason1: "Travel agents",
        Reason2: "Airline reservation agents",
        Reason3: "Tour operators",
        Reason4: "Call center agents",
      },
    ],
    examination:
      "A passing mark of 60% is required on all assignments and exams. A special  distinction is awarded to candidates who obtain a distinction in all, or all but one, all course exams and assignments that make up this Diploma..",
  },
];
